import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const StorybookEmbed = makeShortcode("StorybookEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Flexbox grids`}</h2>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "/foundations/css-utilities/flexbox"
      }}>{`flex utilities`}</a>{` on the container and columns to create a flexbox grid.`}</p>
    <p>{`This can be useful for keeping columns the same height, justifying content and vertically aligning items. The flexbox grid is also great for working with responsive layouts.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--flexbox'
    }]} height="240" mdxType="StorybookEmbed" />
    <h2>{`Float based grid`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.clearfix`}</inlineCode>{` on the container and float utilities with columns for a floated grid layout.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--float'
    }]} height="110" mdxType="StorybookEmbed" />
    <h3>{`Reversed float grid`}</h3>
    <p>{`To reverse the order of columns, use `}<inlineCode parentName="p">{`float-right`}</inlineCode>{` to float columns to the right.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--float-reversed'
    }]} height="110" mdxType="StorybookEmbed" />
    <h2>{`Nesting`}</h2>
    <p>{`You can infinitely nest grid layouts within other columns since the column widths are percentage based. With great flexibility comes great responsibility - be sensible with how far you nest!`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--nested'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Centering a column`}</h2>
    <p>{`Use `}<inlineCode parentName="p">{`.mx-auto`}</inlineCode>{` to center columns within a container.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--centered'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Column widths`}</h2>
    <p>{`Column widths can be used with any other block or inline-block elements to add percentage-based widths.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--column-width'
    }]} height="125" mdxType="StorybookEmbed" />
    <h2>{`Offset columns`}</h2>
    <p>{`Using column offset classes can push a div over X number of columns. They work responsively using the `}<a parentName="p" {...{
        "href": "#responsive-grids"
      }}>{`breakpoints outlined below`}</a>{`.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--column-offset'
    }]} height="150" mdxType="StorybookEmbed" />
    <h2>{`Gutters`}</h2>
    <p>{`Use gutter styles or padding utilities to create gutters. You can use the default gutter style, `}<inlineCode parentName="p">{`gutter`}</inlineCode>{`, or either of its modifiers, `}<inlineCode parentName="p">{`gutter-condensed`}</inlineCode>{` or `}<inlineCode parentName="p">{`gutter-spacious`}</inlineCode>{`. Gutter styles also support responsive breakpoint modifiers. Gutter styles add padding to the left and right side of each column and apply a negative margin to the container to ensure content inside each column lines up with content outside of the grid.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--gutter'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`Use padding utilities to create gutters for more customized layouts.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--gutter-with-padding'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Inline-block grids`}</h2>
    <p>{`Use column widths with `}<inlineCode parentName="p">{`d-inline-block`}</inlineCode>{` as an alternative to floated grids.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--inline-block'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`You can use column widths and other utilities on elements such as lists to create the layout you need while keeping the markup semantically correct.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--inline-block-with-column-width'
    }]} height="300" mdxType="StorybookEmbed" />
    <h2>{`Display table grids`}</h2>
    <p>{`Using `}<a parentName="p" {...{
        "href": "/css-utilities/layout#display"
      }}>{`display table utilities`}</a>{` with columns gives you some alternative layout options.`}</p>
    <p>{`A useful example is being able to keep the height of the container equal across a row when the length of content may differ.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--table'
    }]} height="250" mdxType="StorybookEmbed" />
    <p>{`Note that table cells will fill the width of their container even when the total columns doesn't add up to 12.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--table-cell'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Responsive grids`}</h2>
    <p>{`All the column width classes can be set per breakpoint to create responsive grid layouts. Each responsive style is applied to the specified breakpoint and up.`}</p>
    <h3>{`Breakpoints`}</h3>
    <p>{`We use abbreviations for each breakpoint to keep the class names concise.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Shorthand`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`sm`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width: 544px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`md`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width: 768px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`lg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width: 1004px`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`xl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`min-width: 1280px`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Note:`}</strong>{` The `}<inlineCode parentName="p">{`lg`}</inlineCode>{` breakpoint matches our current page width of `}<inlineCode parentName="p">{`980px`}</inlineCode>{` including left and right padding of `}<inlineCode parentName="p">{`12px`}</inlineCode>{`. This is so that content doesn't touch the edges of the window when resized.`}</p>
    <hr />
    <p>{`In this example at the `}<inlineCode parentName="p">{`sm`}</inlineCode>{` breakpoint 2 columns will show, at the `}<inlineCode parentName="p">{`md`}</inlineCode>{` breakpoint 4 columns will show, and at the `}<inlineCode parentName="p">{`lg`}</inlineCode>{` breakpoint 6 columns will show.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--responsive'
    }]} height="160" mdxType="StorybookEmbed" />
    <p>{`For demonstration, this is how the above example would look at the `}<inlineCode parentName="p">{`sm`}</inlineCode>{` breakpoint.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--responsive-small'
    }]} height="160" mdxType="StorybookEmbed" />
    <p>{`This is how that same example would look at the `}<inlineCode parentName="p">{`md`}</inlineCode>{` breakpoint.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--responsive-medium'
    }]} height="100" mdxType="StorybookEmbed" />
    <p>{`This is how that example would look at the `}<inlineCode parentName="p">{`lg`}</inlineCode>{` breakpoint.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--responsive-large'
    }]} height="100" mdxType="StorybookEmbed" />
    <h2>{`Containers`}</h2>
    <p>{`Container widths match our breakpoints and are available at a `}<inlineCode parentName="p">{`sm`}</inlineCode>{`, `}<inlineCode parentName="p">{`md`}</inlineCode>{`, `}<inlineCode parentName="p">{`lg`}</inlineCode>{`, and `}<inlineCode parentName="p">{`xl`}</inlineCode>{` size. Containers apply a max-width rather than a fixed width for responsive layouts, and they center the container.`}</p>
    <StorybookEmbed framework="css" stories={[{
      id: 'utilities-grid--container'
    }]} height="130" mdxType="StorybookEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      